import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Dialog, Paper, List, Fab, ListItem, Backdrop, CircularProgress } from '@mui/material';
import '@fontsource/roboto/400.css';
import Divider from '@mui/material/Divider';
import { useSelector, useDispatch } from 'react-redux';
import { setLikes, setUser } from '../features/app/appSlice'
import apiClient from "../http-common";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useTheme } from '@mui/material/styles';

import {useEffect, useState} from 'react';
import moment from 'moment';

function LikeButton({onClick}){
    return  <Fab onClick={onClick} sx={{position: 'absolute', bottom: '10%', right: '5%'}} color="success" aria-label="like">
                <FavoriteIcon />
            </Fab>
}
  
function CardDialog({user, selectedUser, onClose}){
    const isMobile = useSelector(state => state.app.isMobile)
    const dispatch = useDispatch()
    const theme = useTheme();

    function handleClose(){
        onClose()
    }

    function handleLikeClick(){
        apiClient.put(`/users/${user._id}/seen`, { seenId: selectedUser._id})
            .then(response => {
                apiClient.put(`/users/${user._id}/liked`, { likedId: selectedUser._id})
                    .then(response => {
                        dispatch(setUser(response.data))
                        onClose()
                        //console.log(response.data); // handle the response data
                    })
                    .catch(error => {
                        console.error(error); // handle any errors
                    });
            })
            .catch(error => {
                console.error(error); // handle any errors
            });

    }

    if(selectedUser !== null){
    return ( 
        <Dialog onClose={handleClose} open={true}>
            <Paper sx={{width: isMobile ? '80vw' : '30vw', 
                        height: '85vh', 
                        background: theme.palette.background.default,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                <Paper sx={{width: '95%', height: '95%', background: theme.palette.background.paper}}>
                    <Box sx={{width: "100%", height: "100%"}}>
                        <Box style={{height: '100%', position: 'relative'}}>
                            <Box sx={{height: '100%', pt: '4%'}}>
                                <List disablePadding sx={{ width: '100%', position: 'relative', overflow: 'auto', maxHeight: '96%'}}>
                                    {selectedUser.photos.map(photoUrl => {
                                        return  <ListItem key={photoUrl} sx={{pt: '0px'}}>
                                                    <img style={{display: 'block', width: '100%', height: '100%', objectFit: 'cover'}} src={photoUrl} alt="User Avatar"></img>
                                                </ListItem>
                                    })}
                                </List> 
                            </Box>
                            <Box style={{position: 'absolute', bottom: '0', left: '0', width: '100%', height: '20%', borderRadius: '0px 0px 3px 3px', backgroundImage: 'linear-gradient(to bottom, transparent, #901124)'}}>
                                <Box style={{textAlign: 'left', position: 'absolute', bottom: '5px', left: '5px', color: 'white', padding: '5px'}}>
                                    <span style={{fontWeight: 'bold'}}>{selectedUser.firstName}, {moment(selectedUser.dob, "YYYYMMDD").fromNow().substring(0, moment(selectedUser.dob, "YYYYMMDD").fromNow().indexOf(" "))}</span>
                                    <span> </span>
                                    <span className={`fi fi-${selectedUser.nationality.toLowerCase()}`}></span>
                                    <br />
                                    <span style={{fontSize: '0.7em'}}>{selectedUser.major}</span>
                                </Box>
                                {!user.liked.includes(selectedUser._id) ? <LikeButton onClick={handleLikeClick}/> : null}
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Paper>
        </Dialog>
    )
    }
    
}


export default function Likes() {
    const likes = useSelector(state => state.app.likes)
    const user = useSelector(state => state.app.user)
    const [selectedUser, setSelectedUser] = useState(null)
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()
    const theme = useTheme();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        apiClient.get(`/users/${user._id}/likedBy`)
            .then(response => {
                dispatch(setLikes(response.data));
                setLoading(false)
                //console.log(response.data); // Do something with the retrieved data
            })
            .catch(error => {
                console.log(error); // Handle any errors that occur
                setLoading(false)
            });
    },[]);

    function handleUserClick(clickedUser){
        setSelectedUser(clickedUser)
        setOpen(true)
    }

    function handleClose(){
        setOpen(false)
    }

    if(loading){
        return <Box sx={{height: '90%', display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{background: theme.palette.background.paper, color: 'white',height: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <p>Liked you</p>
                    </Box>
                    <Divider />
                    <Box sx={{height: '90%', width: '100%', color: 'white', display: 'flex'}}>
                        <CircularProgress sx={{m:'auto'}} color="inherit" />
                    </Box>
                </Box> 
        
    }
    else if(likes.length === 0){
        return (
            <Box sx={{height: '90%', display: 'flex', flexDirection: 'column'}}>
                <Box sx={{background: theme.palette.background.paper, color: 'white',height: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <p>Liked you</p>
                </Box>
                <Divider />
                <Box sx={{height: '90%', width: '100%', color: 'white', display: 'flex'}}>
                    <Typography sx={{m:' auto', fontFamily:'Montserrat'}} variant="p" component="span">Nobody liked you yet</Typography>
                </Box>
            </Box>
        )
    }else{
        return (
            <Box sx={{height: '90%', display: 'flex', flexDirection: 'column'}}>
                <Box  sx={{background: theme.palette.background.paper, color: 'white',height: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <p>Liked you</p>
                </Box>
                <Divider />
                <Box sx={{ height: '90%', width: '100%', display: 'flex'}}>
                    <ImageList sx={{width: '90%',  maxHeight: '90%', m:'auto'}} cols={2} rowHeight={200} >
                        {likes.map(u => {  
                            return <Box key={u._id} sx={{overflow: 'hidden'}}>
                                        <Button onClick={() => handleUserClick(u)} sx={{minWidth: '100%'}}>
                                            <ImageListItem key={1}>
                                                <img alt='profile' style={{objectFit: 'contain'}} src={u.photos[0]} loading="lazy"/>
                                            </ImageListItem>
                                            <Box style={{position: 'absolute', bottom: '0', left: '0', width: '100%', height: '40%', backgroundImage: 'linear-gradient(to bottom, transparent, #901124)'}}/>
                                            <Box style={{textAlign: 'left', position: 'absolute', bottom: '5px', left: '5px', color: 'white', padding: '5px'}}>
                                                <Typography sx={{fontFamily:'Montserrat'}} variant="p" component="span">{u.firstName}, {moment(u.dob, "YYYYMMDD").fromNow().substring(0, moment(u.dob, "YYYYMMDD").fromNow().indexOf(" "))} {<span className={`fi fi-${u.nationality.toLowerCase()}`}></span>}</Typography>
                                                <br />
                                                <Typography sx={{fontSize: '0.7em', fontFamily:'Montserrat'}} variant="p" component="span">{u.major}</Typography>
                                            </Box>
                                        </Button> 
                                    </Box>                    
                        })}  
                    </ImageList>
                </Box>
                {open ? <CardDialog user={user} selectedUser={selectedUser} onClose={handleClose}/> : null}
            </Box>
        )
    }
}
