import { Paper, CircularProgress, Backdrop, Alert, Box, Typography, TextField, Stack, Button, Select, RadioGroup, Radio, FormControlLabel, FormLabel} from "@mui/material"
import { useState, useRef, useEffect } from "react";
import apiClient from "../http-common";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { setAuthToken, setUser, setCode,  setLoading } from '../features/app/appSlice'
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { countryList } from "../countriesList";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import httpCommon from "../http-common";

function Register2({userInfo, setContent}){
    const [selectedImage1, setSelectedImage1] = useState(null);
    const [selectedImage2, setSelectedImage2] = useState(null);
    const [selectedImage3, setSelectedImage3] = useState(null);
    
    const [isLoading, setIsLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertText, setAlertText] = useState('')

    const hiddenFileInput1 = useRef(null);
    const hiddenFileInput2 = useRef(null);
    const hiddenFileInput3 = useRef(null);

    const dispatch = useDispatch();

    async function handleSubmitRegistration(){
        const formData = new FormData();
        const files = [selectedImage1, selectedImage2, selectedImage3]

        if(!selectedImage1 || !selectedImage2 || !selectedImage3){
            setShowAlert(true)
            setAlertText('Please upload 3 images')
            return
        }
        // Check if all files are less than 15MB
        const maxSize = 15 * 1024 * 1024; // 15MB in bytes
        const oversizedFiles = files.filter(file => file.size > maxSize);
        if (oversizedFiles.length > 0) {
            console.log(`The following files are too large. Maximum size is 15MB. ${oversizedFiles.map(file => file.name).join(', ')}`);
            setShowAlert(true)
            setAlertText('Maximum file size is 15MB')
            return;
        }
    
        // Check if all files are images
        const isImage = files.every(file => file.type.startsWith('image/'));
        if (!isImage) {
            console.log('Selected files are not all images.');
            setShowAlert(true)
            setAlertText('Please only upload images')
            return;
        }

        setShowAlert(false)


        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        
        try {
            setIsLoading(true)
            const res = await apiClient.post("/users", userInfo, {
                headers: {
                    "key": process.env.REACT_APP_SECRET_KEY,
                    },
            });
      
            apiClient.post(`users/${res.data._id}/photos`, formData, {  
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                try {
                    const res = await apiClient.post("/users/login", {email: userInfo.email, password: userInfo.password})
                    if(res.data.success){
                        setIsLoading(false)
                        dispatch(setLoading(true))
                        localStorage.setItem('token', res.data.token);
                        // send JWT with each request
                        httpCommon.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
                        dispatch(setUser(res.data.user));
                    }
                
                    } catch (err) {
                        console.log(err)
                        alert("Wrong email or password.");
                    }
            })
            .catch(error => {
                setIsLoading(false)
                console.log(error);
            });             
        } catch (err) {
                console.log(err.response?.data)
                setIsLoading(false)
        }
    }
    return(
        <Box sx={{height: '100%', width: '100%'}}>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{color: 'white', width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
                <Stack sx={{height: '10%', width: '100%'}}>
                    {showAlert ? 
                        <Alert severity="error">{alertText}</Alert>
                        : <h3 style={{margin: 'auto'}}>Upload 3 photos</h3>
                    }
                </Stack>
                <Box sx={{height: '80%', width: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column'}}>
                    <Paper sx={{background: 'white', overflow: 'hide', width: '90%', minHeight: '90%', m: 'auto', mt: '5%', display: 'flex'}}>
                        <Button sx={{m: 'auto', width: '95%', height: '95%'}} onClick={() => hiddenFileInput1.current.click()}>
                            {selectedImage1 ? (<img style={{objectFit: 'contain', borderRadius: '5px 5px 5px 5px'}}
                                alt="not found"
                                height={"100%"}
                                width={"100%"}
                                src={URL.createObjectURL(selectedImage1)}
                        />) : <AddCircleOutlineIcon sx={{ fontSize: 80 }}/>}
                        </Button>
                    </Paper>
                    <input type="file" name="myImage1" ref={hiddenFileInput1} style={{display: 'none'}}
                        onChange={(event) => {
                            //console.log(event.target.files[0]);
                            setSelectedImage1(event.target.files[0]);
                    }}/>
                    <Paper sx={{background: 'white', width: '90%', minHeight: '90%', m: 'auto', mt: '5%', display: 'flex'}}>
                        <Button sx={{m: 'auto', overflow: 'hidden', width: '95%', height: '95%'}} onClick={() => hiddenFileInput2.current.click()}>
                            {selectedImage2 ? (<img style={{objectFit: 'contain', borderRadius: '5px 5px 5px 5px'}}
                            alt="not found"
                            height={"100%"}
                            width={"100%"}
                            src={URL.createObjectURL(selectedImage2)}
                        />) : <AddCircleOutlineIcon sx={{ fontSize: 80 }}/>}
                        </Button>
                    </Paper>
                    <input type="file" name="myImage2" ref={hiddenFileInput2} style={{display: 'none'}}
                        onChange={(event) => {
                            //console.log(event.target.files[0]);
                            setSelectedImage2(event.target.files[0]);
                    }}/>
                    <Paper sx={{background: 'white', width: '90%', minHeight: '90%', m: 'auto', my: '5%', display: 'flex'}}>
                        <Button sx={{m: 'auto', overflow: 'hidden', width: '95%', height: '95%'}} onClick={() => hiddenFileInput3.current.click()}>
                            {selectedImage3 ? (<img style={{objectFit: 'contain', borderRadius: '5px 5px 5px 5px'}}
                            alt="not found"
                            height={"100%"}
                            width={"100%"}
                            src={URL.createObjectURL(selectedImage3)}
                        />) : <AddCircleOutlineIcon sx={{ fontSize: 80 }}/>}
                        </Button>
                    </Paper>
                    <input type="file" name="myImage3" ref={hiddenFileInput3} style={{display: 'none'}}
                        onChange={(event) => {
                            //console.log(event.target.files[0]);
                            setSelectedImage3(event.target.files[0]);
                    }}/>
                </Box>
                <Box sx={{height: '10%', display: 'flex'}}>
                    <Button sx={{m: 'auto'}} onClick={handleSubmitRegistration}>
                        <Typography sx={{fontSize: '1.8em', fontFamily:'Montserrat', p: '2%'}} variant="p" component="span">Upload</Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

function Register1({userInfo, setUserInfo, setContent}){
    const [nameError, setNameError] = useState(false)    
    const [lastNameError, setLastNameError] = useState(false)    
    const [majorError, setMajorError] = useState(false)    
    const [nationalityError, setNationalityError] = useState(false)    
    const [dobError, setDOBError] = useState(false)    
    const [genderError, setGenderError] = useState(false)    
    const [preferenceError, setPreferenceError] = useState(false)  

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({
          ...userInfo,
          [name]: value,
        });

        //console.log(userInfo)
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        //console.log(userInfo)
        setNameError(false);
        setLastNameError(false);
        setMajorError(false);
        setNationalityError(false);
        setDOBError(false);
        setGenderError(false);
        setPreferenceError(false);
        let error = false;

        if(!userInfo.firstName || userInfo.firstName.length > 20){
            setNameError(true);
            error = true;
        } 
        
        if(!userInfo.lastName || userInfo.lastName.length > 20){
            setLastNameError(true);
            error = true;
        } 

        if(!userInfo.major){
            setMajorError(true);
            error = true;
        } 

        if(!userInfo.nationality){
            setNationalityError(true);
            error = true;
        } 

        if(!isValidDateOfBirth(userInfo.dob)){
            setDOBError(true);
            error = true;
        } 

        if(!userInfo.gender){
            setGenderError(true);
            error = true;
        } 

        if(!userInfo.preference){
            setPreferenceError(true);
            error = true;
        } 

        if(!error){
            setContent('register2')
        }

      };

      const isValidDateOfBirth = (dateOfBirth) => {
        const date = moment(dateOfBirth, 'YYYYMMDD');
        const currentDate = moment();
      
        if (!date.isValid()) {
          return false;
        }
      
        if (date > currentDate) {
          return false;
        }
      
        const age = currentDate.diff(date, 'years');
      
        if (age < 0 || age > 120) {
          return false;
        }
      
        return true;
      }  

    return(
        <Box sx={{height: '100%'}}>
            <Box sx={{color: 'white', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center'}}>
                <form style={{display: 'flex', flexDirection: 'column'}} onSubmit={handleSubmit}>
                    <TextField error={nameError} color="common" name="firstName" id="firstName" label="First Name" variant="standard" value={userInfo.firstName} onChange={handleChange}/>
                    <TextField error={lastNameError} color="common" name="lastName" id="lastName" label="Last Name" variant="standard" value={userInfo.lastName} onChange={handleChange}/>
                    <FormControl sx={{mt: '10%'}} fullWidth>
                        <InputLabel error={majorError} color="common" id="major-select-label">Major</InputLabel>
                        <Select error={majorError}
                            sx={{
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(228, 219, 233, 0.25)',
                            },
                            }}
                            name="major"
                            labelId="major-select"
                            id="major-select"
                            value={userInfo.major}
                            label="Major"
                            onChange={handleChange}
                            >
                            <MenuItem key="comp" value={'Computer Engineering'}>Computer Engineering</MenuItem>
                            <MenuItem key="mech" value={'Mechanical Engineering'}>Mechanical Engineering</MenuItem>
                            <MenuItem key="chem" value={'Chemical Engineering'}>Chemical Engineering</MenuItem>
                            <MenuItem key="elec" value={'Electrical Engineering'}>Electrical Engineering</MenuItem>
                            <MenuItem key="bm" value={'Business Management'}>Business Management</MenuItem>
                            <MenuItem key="econ" value={'Economics'}>Chemical Engineering</MenuItem>
                            <MenuItem key="law" value={'Law'}>Law</MenuItem>
                            <MenuItem key="med" value={'Medicine'}>Medicine</MenuItem>
                            <MenuItem key="mava" value={'MAVA'}>MAVA</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{mt: '10%'}} fullWidth>
                        <InputLabel error={nationalityError} color="common" id="nationality-select-label">Nationality</InputLabel>
                        <Select
                            error={nationalityError}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: '40vh',
                                        maxWidth: '30%'
                                    }
                                }
                            }}
                            sx={{
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                }}
                            name="nationality"
                            labelId="nationality-select"
                            id="nationality-select"
                            value={userInfo.nationality}
                            label="Nationality"
                            onChange={handleChange}
                            >
                            {countryList.map(countryObj => {
                                return  <MenuItem key={countryObj.code} value={countryObj.code}>
                                            {countryObj.name}
                                        </MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker 
                            label="Date of Birth"
                            value={userInfo.dob}
                            error
                            onChange={(newValue) => {
                                const formattedDate = newValue ? moment(newValue._d).format('YYYYMMDD') : null;
                                setUserInfo({
                                    ...userInfo,
                                    dob: formattedDate,
                                });
                            }}
                            inputFormat="DD/MM/YYYY" 
                            renderInput={(params) => <TextField  color="common" sx={{mt: '10%'}} {...params} error={dobError}/>}
                        />
                    </LocalizationProvider>
                    <FormControl>
                        <FormLabel error={genderError} sx={{mt: '7%'}} id="gender-radio-buttons">Gender</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="gender-radio-buttons"
                            name="gender"
                            value={userInfo.gender}
                            onChange={handleChange}
                        >
                            <Box sx={{m: 'auto'}}>
                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                            </Box>    
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel error={preferenceError} id="preference-radio-buttons">Preference</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="preference-radio-buttons"
                            name="preference"
                            value={userInfo.preference}
                            onChange={handleChange}
                        >
                            <Box sx={{m: 'auto'}}>
                                <FormControlLabel value="women" control={<Radio />} label="Women" />
                                <FormControlLabel value="men" control={<Radio />} label="Men" />
                            </Box>
                        </RadioGroup>
                    </FormControl>
                    <Button type="submit" sx={{color: 'white'}} variant="outlined">               
                        <Typography sx={{fontFamily:'Montserrat', p: '2%'}} variant="p" component="span">Submit</Typography>
                    </Button>
                </form>
            </Box>
        </Box>
    )
}

function Login({userInfo, setUserInfo, setContent, setParentContent}){
    const dispatch = useDispatch();
    const [wrongEmail, setWrongEmail] = useState(false)
    const [wrongPassword, setWrongPassword] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertContent, setAlertContent] = useState('')



    function validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        const kuEmailRegex = /^[^\s@]+@ku\.edu\.tr$/;
        return emailRegex.test(email) && kuEmailRegex.test(email);
    }

    const handleEmailChange = (event) => {
        setUserInfo({
            ...userInfo,
            email: event.target.value,
        });
      };
    
    const handlePasswordChange = (event) => {
        setUserInfo({
            ...userInfo,
            password: event.target.value,
          });
    };
    
    const handleLoginClick = async () => {
        // Handle login logic here using email and password state
        let smthWrong = false
        if (!validateEmail(userInfo.email)) {
            setWrongEmail(true)
            smthWrong = true
        }else{
            setWrongEmail(false)
        }
        
        if (userInfo.password.length < 6) {
            setWrongPassword(true)
            smthWrong = true
        }else{
            setWrongPassword(false)
        }

        if(smthWrong){
            return
        }

        if(userInfo.email && userInfo.password){
            const postData = {
            email: userInfo.email,
            password: userInfo.password,
            };
        
            try {
            const res = await apiClient.post("/users/login", postData, {
                headers: {
                "x-access-token": "token-value",
                },
            })

            if(res.data.success){
                dispatch(setLoading(true))
                //dispatch(setAuthToken(res.data.token))
                localStorage.setItem('token', res.data.token);

                // send JWT with each request
                httpCommon.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
                dispatch(setUser(res.data.user));

                //setUser(res.data.user)
            }
        
            //setPostResult(fortmatResponse(result));
            } catch (err) {
                console.log(err)
                setShowAlert(true)
                setAlertContent("Wrong email or password")
            //setPostResult(fortmatResponse(err.response?.data || err));
            }
        }
    };
    
      const handleRegisterClick = () => {
        // Handle register logic here
        let smthWrong = false
        if (!validateEmail(userInfo.email)) {
            setWrongEmail(true)
            smthWrong = true
        }else{
            setWrongEmail(false)
        }
        
        if (userInfo.password.length < 6) {
            setWrongPassword(true)
            smthWrong = true
        }else{
            setWrongPassword(false)
        }

        if(smthWrong){
            return
        }

        apiClient.get(`/users/email/${userInfo.email}`, {
            headers: {
                "key": process.env.REACT_APP_SECRET_KEY,
                },
        })
            .then(response => {
                //console.log(response.data.message);
                if(response.data.exists){
                    setShowAlert(true)
                    setAlertContent('User with given email exists')
                }else{
                    setUserInfo({
                        ...userInfo,
                        email: userInfo.email.toLowerCase(),
                        password: userInfo.password
                      });
                    setContent('register0')
    
                    apiClient.get(`/users/${userInfo.email}/verify`)
                    .then(response => {
                        //dispatch(setCards(response.data));
                        dispatch(setCode(response.data.code))
                    })
                    .catch(error => {
                        console.log(error); // Handle any errors that occur
                    });
                }
                
            })
            .catch(error => {
                console.error(error);
            });   
        };
    return (
        <Box sx={{height: '100%'}}>
            <Box sx={{color: 'white', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center'}}>
                <Typography sx={{fontFamily:'Montserrat', p: '2%'}} variant="p" component="span">KUmatch</Typography>
                <Stack sx={{width: '60%', height: '40%'}} spacing={2}>
                    <Box sx={{mb: '10%',width: '100%', height: '20%'}}>
                        {showAlert ? <Alert severity="error">{alertContent}</Alert> : null}
                    </Box>
                    <TextField 
                        error={wrongEmail} helperText={wrongEmail ? "Please enter a valid KU email address" : ' '} color="common" id="email" label="KU email" variant="outlined" value={userInfo.email} onChange={handleEmailChange}/>
                    <TextField error={wrongPassword} helperText={wrongPassword ? "Please enter atleast 6 characters" : ' '} color="common" type="password" id="password" label="Password" variant="outlined" value={userInfo.password} onChange={handlePasswordChange}/>
                </Stack>
                <Stack spacing={5} direction="row">
                    <Button variant="outlined" onClick={handleLoginClick} sx={{color: 'white'}} >               
                        <Typography sx={{fontFamily:'Montserrat', p: '2%'}} variant="p" component="span">Login</Typography>
                    </Button>
                    <Button variant="outlined" onClick={handleRegisterClick} sx={{color: 'white'}} >               
                        <Typography sx={{fontFamily:'Montserrat', p: '2%'}} variant="p" component="span">SignUp</Typography>
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}

export default function LoginView(props){
    const [content, setContent] = useState('login');
    const isMobile = useSelector(state => state.app.isMobile)

    const [userInfo, setUserInfo] = useState({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        major: '',
        nationality: '',
        dob: '',
        gender: '',
        preference: ''
    });

    




    const contentEvaluator = () =>{
        switch(content){
            case "login" : return <Login setUserInfo={setUserInfo} userInfo={userInfo} setContent={setContent} setParentContent={props.setContent}/>;
            case "register0" : return <Register0 userInfo={userInfo}/>;
            case "register1" : return <Register1 userInfo={userInfo} setUserInfo={setUserInfo} setContent={setContent}/>;
            case "register2" : return <Register2 userInfo={userInfo} setContent={props.setContent}/>;
            default : return <h1>Hello World!</h1>;
        }      
    }

    function Register0({userInfo}){
        const [enteredCode, setEnteredCode] = useState('')
        const code = useSelector(state => state.app.verificationCode)
        const [error, setError] = useState(false)

        function verifyCode(){
            // Generate a random 6-digit verification code
            if(enteredCode == code){
                setContent("register1")
            }else{
                setError(true)
            }
        }

        return(
            <Box sx={{height: '100%'}}>
                <Box sx={{color: 'white', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center'}}>
                    <Stack sx={{width: '50%'}} spacing={2}>
                        <Typography sx={{fontFamily:'Montserrat', p: '2%'}} variant="p" component="span">Email verification code</Typography>
                        <TextField 
                            error={error}
                            label={error ? 'Wrong code' : null}
                            sx={{input: {textAlign: "center"}}}
                            onChange={(e) => setEnteredCode(e.target.value)} color="common" id="outlined-basic" variant="outlined" />
                        <Button onClick={() => verifyCode()} sx={{color: 'white'}} variant="outlined">               
                            <Typography sx={{fontFamily:'Montserrat', p: '2%'}} variant="p" component="span">Submit</Typography>
                        </Button>
                    </Stack>
                    
                </Box>
            </Box>
        )
    }

    return (
        <Paper sx={{width: isMobile ? "100vw" : "35vw", height: "calc(var(--vh, 1vh) * 100)", borderRadius: '0px 0px 0px 0px'}}>
            {contentEvaluator()}
        </Paper>
    )
}