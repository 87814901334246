import {useState} from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import ForumIcon from '@mui/icons-material/Forum';
import PersonIcon from '@mui/icons-material/Person';
import Cards from './Cards'
import Likes from './Likes'
import Chats from './Chats'
import Profile from './Profile'
import Search from './Search';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';



export default function MainView() {
    const [content, setContent] = useState('cards');
    const user = useSelector(state => state.app.user)
    const isMobile = useSelector(state => state.app.isMobile)
    const theme = useTheme();

    const handleChange = (event, newContent) => {
        setContent(newContent);
    };

    const switchCaseEvaluator = () =>{
            switch(content){
                case "cards" : return <Cards />;
                case "likes" : return <Likes />;
                case "chats" : return <Chats />;
                case "search" : return <Search />;
                case "profile" : return <Profile user={user} />;
                default : <h1>Hello World!</h1>;
            }      
    }

    return(
        <Box sx={{background: theme.palette.background.default, width: isMobile ? "100vw" : "35vw", height: "calc(var(--vh, 1vh) * 100)", borderRadius: '0px 0px 0px 0px'}}>
            {switchCaseEvaluator()}
                <BottomNavigation sx={{width: '100%', height: '10%'}} value={content} onChange={handleChange}>
                    <BottomNavigationAction value="cards" icon={<ViewCarouselIcon />} />
                    <BottomNavigationAction value="likes" icon={<FavoriteIcon />} />
                    <BottomNavigationAction value="search" icon={<SearchIcon />} />
                    <BottomNavigationAction value="chats" icon={<ForumIcon />} />
                    <BottomNavigationAction value="profile" icon={<PersonIcon />} />
                </BottomNavigation>
        </Box>
    )
}