import { configureStore } from '@reduxjs/toolkit'
import appReducer from '../features/app/appSlice'
import { composeWithDevTools } from 'redux-devtools-extension';

export const store = configureStore({
  reducer: {
    app: appReducer,
  },
  enhancers: {
    dev: composeWithDevTools()
  }
})