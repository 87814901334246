import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: null,
  cards: [],
  likes: [],
  matches: [],
  chats: [],
  searches: [],
  openedChat: {},
  isMobile: false,
  authToken: {},
  loading: false,
  verificationCode: ''
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setCards: (state, action) => {
      state.cards = action.payload;
    },
    setMatches: (state, action) => {
      state.matches = action.payload;
    },
    setLikes: (state, action) => {
      state.likes = action.payload;
    },
    popCards: (state, action) => {
      state.cards.pop();
    },
    setChats: (state, action) => {
      state.chats = action.payload;
    },
    addChat: (state, action) => {
      state.chats.push(action.payload);
    },
    setSearches: (state, action) => {
      state.searches = action.payload;
    },
    updateChatInChats: (state, action) => {
      state.chats = state.chats.map(chat => {
        if (chat._id === action.payload._id) {
          return action.payload; // replace existing chat with updated chat
        } else {
          return chat; // return existing chat if _id does not match
        }
      });
    },    
    setOpenedChat: (state, action) => {
      state.openedChat = action.payload;
    },
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCode: (state, action) => {
      state.verificationCode = action.payload;
    },
    addMsgToChat: (state, action) => {
      state.openedChat.messages.push(action.payload);
    },
    markMsgsAsSeen: (state, action) => {
      let newChats = []

      for(const chat of state.chats){
        if(chat._id.toString() === action.payload){
          newChats.push({...chat, messages: chat.messages.map(message => {
            if(message.sentBy.toString() !== state.user._id.toString()){
                return {...message, seen: true}
            }else{
                return message
            }
          })})
        }else{
          newChats.push(chat)
        }
      }
      state.chats = newChats;
    },
  }
})

// Action creators are generated for each case reducer function
export const { addChat, setSearches, updateChatInChats, setCode, setLoading, addMsgToChat, markMsgsAsSeen, setAuthToken, setIsMobile, setChats, setOpenedChat, setMatches, setLikes, setCards, popCards, setUser } = appSlice.actions

export default appSlice.reducer