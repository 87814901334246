import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/Button';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ChatView from './ChatView';
import { Skeleton, Stack } from '@mui/material';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { markMsgsAsSeen, setMatches, setLoading, setOpenedChat, setChats, updateChatInChats } from '../features/app/appSlice'
import apiClient from "../http-common";
import { useTheme } from '@mui/material/styles';
import {useEffect} from 'react';
import socket from '../socket';
import DoneAllIcon from '@mui/icons-material/DoneAll';


function ChatList({setContent, matches, openedChatID}){
    const dispatch = useDispatch()
    const user = useSelector(state => state.app.user)
    const loading = useSelector(state => state.app.loading)
    const isMobile = useSelector(state => state.app.isMobile)
    const theme = useTheme();
    const chats = useSelector(state => state.app.chats)

    function updateChats(){
        apiClient.get('/chats/user', {
            params: {
                userID: user._id
            }
        })
        .then(response => {
            //console.log(response.data);
            dispatch(setChats(response.data))
            dispatch(setLoading(false))
        })
        .catch(error => {
            console.log(error.response);
        });
    }

    const newMessageHandler = (chat) => {
        const chatWithId = chats.find(c => c._id === chat._id);
        if(chatWithId){ 
            dispatch(updateChatInChats(chat))
            if(openedChatID === chat._id){
                dispatch(setOpenedChat(chat));
            }
        }
    };
  
    const seenMessageHandler = (chat) => {
        const chatWithId = chats.find(c => c._id === chat._id);
        if(chatWithId){ 
            updateChatInChats(chat)
            if(openedChatID === chat._id){
                dispatch(setOpenedChat(chat));
            }
        }
    };

    useEffect(() => {
        if (chats.length === 0 || matches.length === 0) {
          dispatch(setLoading(true));
        }

        updateChats()
      }, []);

    useEffect(() => {
        socket.on("newMessage", newMessageHandler);
        socket.on("seenMessage", seenMessageHandler);
      
        return () => {
          socket.off("newMessage", newMessageHandler);
          socket.off("seenMessage", seenMessageHandler);
        };
      }, [chats]);

    function handleMatchClick(match){
        let chat = {}
        const participants = [match._id, user._id]

        console.log(chats)
        for (var i = 0; i < chats.length; i++) { 
            if(chats[i].participants.every(participant => participants.includes(participant._id))){
                dispatch(setOpenedChat(chats[i]))
                setContent('chatView')
                return
            }
        }

        apiClient.get('/chats/participants', {
            params: {
                participants: [match._id, user._id]
            }
        })
        .then(response => {
            console.log(response.data);
            dispatch(setOpenedChat(response.data))
            setContent('chatView')
        })
        .catch(error => {
            if(error.response.status === 404){
                const openedChat = {
                    messages: [],
                    participants: [ user, match]
                }
    
                dispatch(setOpenedChat(openedChat))
                setContent('chatView')
            }   
        }); 
    }

    function handleChatClick(chat){
        setContent('chatView')
        dispatch(setOpenedChat(chat))
        apiClient.get(`/chats/${chat._id}`)
        .then(response => {
            console.log(response.data); // Do something with the chat data
            //dispatch(markMsgsAsSeen(chat._id))
            dispatch(setOpenedChat(response.data))
        })
        .catch(error => {
            console.error(error); // Handle any errors that occurred during the request
        });  
    }

    function getMessageTime(date) {
        const now = new Date();
        const messageDate = new Date(date);
        const messageTime = messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

        // Check if message is from today
        if (messageDate.toDateString() === now.toDateString()) {
          return messageTime;
        }
      
        // Check if message is from within the past week
        const oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
        if (messageDate >= oneWeekAgo) {
          const dayOfWeek = messageDate.toLocaleDateString([], { weekday: 'long' });
          return `${dayOfWeek}`;
        }
      
        // Message is from more than a week ago
        const day = messageDate.getDate().toString().padStart(2, '0');
        const month = (messageDate.getMonth() + 1).toString().padStart(2, '0');
        const year = messageDate.getFullYear().toString();
        return `${day}/${month}/${year}`;
      }
      

    function getUnreadCount(messages){
        let count = 0;
        for(const message of messages){
            if(message.sentBy !== user._id && message.seen === false){
                count++;
            }
        }

        return count;
    }

    function UnreadBubble({messages}){
        const unreadCnt = getUnreadCount(messages)

        if(unreadCnt){
            return <Typography sx={{
                        background: theme.palette.primary.main,
                        width:'30%',
                        fontFamily:'Montserrat',
                        fontSize : '0.9em',
                        borderRadius: '50%',
                        mt: '10%'
                    }} variant="p" component="span">
                        {unreadCnt}
                    </Typography>
        }else{
            return null
        }
    }

    function MatchesSkeleton(){
        return  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%'}}>
                    <Skeleton variant="circular" sx={{ml: '1%'}} width={isMobile ? '30%' : '20%'} height={'90%'}/>
                    <Skeleton variant="circular" sx={{ml: '1%'}} width={isMobile ? '30%' : '20%'} height={'90%'}/>
                </Box>
    }

    function ChatsSkeleton(){
        return  <Box sx={{display: 'flex',   alignItems: 'center', width: '100%', height: '25%'}}>
                    <Skeleton variant="circular" sx={{ml: '1%'}} width={isMobile ? '30%' : '20%'} height={'90%'}/>
                    <Stack spacing={1} sx={{width: '80%', ml: '5%'}}>
                        <Skeleton variant="text" sx={{fontSize: '2rem' }} width={'50%'} />
                        <Skeleton variant="text" sx={{fontSize: '1.5rem' }} width={'80%'} />
                    </Stack>
                </Box>
    }

    function MessageStatus({sx, message}){
        if(message.sentBy === user._id){
            return message.seen ? <DoneAllIcon sx={{...sx, color: '#53bdeb'}}/> : <DoneAllIcon sx={{...sx, color: '#a6b9c6'}}/>
        }
    }

    return (
    <Box sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
        <Box sx={{background: theme.palette.background.paper, color: 'white',height: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <p>Matches</p>
        </Box>
        <Divider />
        <Box sx={{height: '16%', display: 'flex', overflowX: 'auto', backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
            {!loading ? matches.map( match => 
                <IconButton key={match._id} sx={{minWidth: isMobile ? '30%' : '25%', width: isMobile ? '30%' : '25%', }} onClick={() => handleMatchClick(match)}>
                    <Avatar sx={{height: '100%', width: '100%'}} alt="Remy Sharp" src={match.photos[0]}/>
                    <Box style={{position: 'absolute', bottom: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.2)'}}/>
                    <Box style={{textAlign: 'left', position: 'absolute',  color: 'white', padding: '5px'}}>
                        <Typography sx={{fontFamily:'Montserrat'}} variant="p" component="span">{match.firstName}</Typography>
                    </Box>                
                </IconButton>
            ) : <MatchesSkeleton/>}
        </Box>
        <Box sx={{background: theme.palette.background.paper, color: 'white',height: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <p>Messages</p>
        </Box>
        <Divider />
        <Box sx={{height: '65%', overflow: 'auto'}}>
            {!loading ? chats.map(chat => 
                <Box key={chat._id} sx={{height: isMobile ? '17%' : '20%'}}>
                    <Button onClick={() => handleChatClick(chat)} sx={{height: '100%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                        <Avatar sx={{height: '85%', width: '15%'}} alt="Remy Sharp" src={user._id === chat.participants[0]._id ? chat.participants[1].photos[0] : chat.participants[0].photos[0]}/>
                        <Box style={{width: '64%', textAlign: 'left',  color: 'white', padding: '5%', display: 'flex', flexDirection: 'column'}}>
                            <Typography sx={{fontFamily:'Montserrat'}} variant="h7" component="span">{user._id === chat.participants[0]._id ? chat.participants[1].firstName : chat.participants[0].firstName}</Typography>
                            <Box sx={{ mt: '2%', display: 'flex', alignItems: 'center'}}>
                                <MessageStatus sx={{fontSize: '1em', fontFamily:'Montserrat', mr: '1%'}} message={chat.messages.at(-1)}/>
                                <Typography sx={{fontFamily:'Montserrat', fontSize : '0.85em', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} variant="p" component="span">{chat.messages.at(-1).body}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{position: 'absolute', right: '5%', top: '5%', color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '20%', height: '65%'}}>
                            <Typography sx={{
                                mt: '10%',
                                fontFamily:'Montserrat',
                                fontSize : '0.8em',
                            }} variant="p" component="span">
                                {getMessageTime(chat.messages.at(-1).time)}
                            </Typography>
                            <UnreadBubble messages={chat.messages}/>
                        </Box>
                    </Button>
                </Box>
            ) : <ChatsSkeleton />}
        </Box>
    </Box>
    )
}

export default function Chats(){
    const [content, setContent] = useState('chats');
    const user = useSelector(state => state.app.user)
    const openedChatID = useSelector(state => state.app.openedChat._id)

    const dispatch = useDispatch()
    const authToken = useSelector(state => state.app.authToken)
    const matches = useSelector(state => state.app.matches)


    function updateMatches(){
        apiClient.get(`/users/${user._id}/matches`)
        .then(response => {
            dispatch(setMatches(response.data));
            //console.log(response.data); // Do something with the retrieved data
        })
        .catch(error => {
            console.log(error); // Handle any errors that occur
        });
    }

    

    

    useEffect(() => {
        if (matches.length === 0) {
          dispatch(setLoading(true));
        }
        
        updateMatches()

      }, []);

    useEffect(() => {
        // updateMatches()
        // updateChats() 

        // socket.on("newMessage", (chat) => {
        //     console.log(' u got a new message')
        //     const chatWithId = chats.find(c => c._id === chat._id);
        //     if(chatWithId){ 
        //         console.log('here')
        //         updateChats() 
        //         dispatch(setOpenedChat(chat)) 
        //     }
        // });

        // socket.on("seenMessage", (chat) => {
        //     const chatWithId = chats.find(c => c._id === chat._id);
        //     if(chatWithId){
        //         updateChats() 
        //         dispatch(setOpenedChat(chat)) 
        //     }
        // });

        // return () => {
        //     socket.off("newMessage");
        //     socket.off("seenMessage");
        // }
    },[content]);


    

    return(
        <Box sx={{height: '90%'}}>
            {content === 'chats' ? <ChatList openedChatID={openedChatID} matches={matches} setContent={setContent}/> : <ChatView setContent={setContent}/>}
        </Box>
    )
}