import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Typography, Paper, Divider, Skeleton } from '@mui/material';

import FavoriteIcon from '@mui/icons-material/Favorite';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { popCards, setUser, setCards, setLoading} from '../features/app/appSlice'
import apiClient from "../http-common";
import { useTheme } from '@mui/material/styles';
import "/node_modules/flag-icons/css/flag-icons.min.css";

import {useEffect, useState} from 'react';

export default function Cards(){
    const cards = useSelector(state => state.app.cards)
    const user = useSelector(state => state.app.user)
    const loading = useSelector(state => state.app.loading)
    const [timePassed, setTimePassed] = useState(getTimePassed());


    const dispatch = useDispatch()
    const theme = useTheme()
    const authToken = useSelector(state => state.app.authToken)

    function getFlagEmoji(countryCode) {
        const codePoints = countryCode
          .toUpperCase()
          .split('')
          .map(char =>  127397 + char.charCodeAt());
        return String.fromCodePoint(...codePoints);
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimePassed(getTimePassed());
          }, 1000);
      

        apiClient.get(`/users/${user._id}/cards`)
            .then(response => {
                dispatch(setCards(response.data));
                dispatch(setLoading(false))
                //console.log(response.data); // Do something with the retrieved data
            })
            .catch(error => {
                console.log(error); // Handle any errors that occur
            });

        return () => clearInterval(intervalId);

    },[]);

    function updateCards(){
        dispatch(setLoading(true))
        apiClient.get(`/users/${user._id}/cards`)
            .then(response => {
                dispatch(setCards(response.data));
                dispatch(setLoading(false))
                //console.log(response.data); // Do something with the retrieved data
            })
            .catch(error => {
                dispatch(setLoading(false))
                console.log(error); // Handle any errors that occur
            });
    }
    function handleLike(){
        apiClient.put(`/users/${user._id}/seen`, { seenId: cards.at(-1)._id})
            .then(response => {
                apiClient.put(`/users/${user._id}/liked`, { likedId: cards.at(-1)._id})
                    .then(response => {
                        dispatch(setUser(response.data))
                        //console.log(response.data); // handle the response data
                        dispatch(popCards())
                        if(cards.length === 1){
                            updateCards()
                        }
                    })
                    .catch(error => {
                        console.error(error); // handle any errors
                    });
            })
            .catch(error => {
                console.error(error); // handle any errors
            });
    }

    function handleDislike(){
        apiClient.put(`/users/${user._id}/seen`, { seenId: cards.at(-1)._id})
            .then(response => {
                dispatch(setUser(response.data))
                //console.log(response.data); // handle the response data
                dispatch(popCards())
                if(cards.length === 1){
                    updateCards()
                }
            })
            .catch(error => {
                console.error(error); // handle any errors
            });
    }

    function getAge(card){
        var today = new Date();
        var birthDate = new Date(card.dob.substring(0, 4), card.dob.substring(4, 6) - 1, card.dob.substring(6, 8));
        var age = today.getFullYear() - birthDate.getFullYear();
        var month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;    
    }

    function getTimePassed() {
        const releaseDate = new Date(process.env.REACT_APP_LAUNCH_DATE);
        //console.log(process.env.REACT_APP_LAUNCH_DATE)

        const timePassed = Date.now() - releaseDate.getTime();
        const seconds = Math.floor((timePassed / 1000) % 60).toString().padStart(2, '0');
        const minutes = Math.floor((timePassed / (1000 * 60)) % 60).toString().padStart(2, '0');
        const hours = Math.floor(timePassed / (1000 * 60 * 60)).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }

    if(loading){
        return <Box sx={{width: "100%", height: "90%", display: 'flex', flexDirection: 'column'}}>
                <Box sx={{background: theme.palette.background.paper, color: 'white',height: '10%', display: 'flex', }}>
                    <p style={{margin: 'auto'}} >KUmatch</p>
                </Box>
                <Divider />
                <Box sx={{height: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Skeleton variant="rounded" width={'90%'} height={'80%'} />
                    <Box sx={{width: '90%'}}>
                        <Skeleton variant="text" width={'30%'} sx={{ fontSize: '1.5rem' }} />
                        <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1.5rem' }} />
                    </Box>
                </Box>
        </Box>
    }else if (cards.length > 0) {
        return (
            <Box sx={{width: "100%", height: "90%", display: 'flex', flexDirection: 'column'}}>
                <Box sx={{background: theme.palette.background.paper, color: 'white',height: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <p>KUmatch</p>
                </Box>
                <Divider />
                <Box sx={{height: '90%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Paper style={{height: '90%', width: '90%', position: 'relative'}}>
                        <Box sx={{height: '100%', pt: '4%'}}>
                            <List disablePadding sx={{ width: '100%', position: 'relative', overflow: 'auto', maxHeight: '96%'}}>
                                {cards.at(-1).photos.map(photoUrl => {
                                    return  <ListItem key={photoUrl} sx={{pt: '0px'}}>
                                                <img style={{display: 'block', width: '100%', height: '100%', objectFit: 'cover', borderRadius: '4px 4px 0px 0px'}} src={photoUrl} alt="User Avatar"></img>
                                            </ListItem>
                                })}
                            </List> 
                        </Box>
                        <Box style={{borderRadius: '0px 0px 3px 3px', position: 'absolute', bottom: '0', left: '0', width: '100%', height: '20%', backgroundImage: 'linear-gradient(to bottom, transparent, #901124)'}}>
                            <Box style={{textAlign: 'left', position: 'absolute', bottom: '10%', left: '3%', color: 'white', padding: '5px'}}>
                                <span style={{fontWeight: 'bold'}}>
                                    {cards.at(-1).firstName}, {getAge(cards.at(-1))} {<span className={`fi fi-${cards.at(-1).nationality.toLowerCase()}`}></span>}
                                </span>
                                <br />
                                <span style={{fontSize: '0.7em'}}>{cards.at(-1).major}</span>
                            </Box>
                            <Box sx={{position: 'absolute', bottom: '50%', right: '0', transform: 'translate(0, 60%)', width: '50%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                                <Fab onClick={handleDislike} color="error" aria-label="dislike">
                                    <CloseIcon />
                                </Fab>
                                <Fab onClick={handleLike} color="success" aria-label="like">
                                    <FavoriteIcon />
                                </Fab>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
                
            </Box>
        )
    } else {
        return (
            <Box sx={{width: "100%", height: "90%", display: 'flex', flexDirection: 'column'}}>
                <Box sx={{background: theme.palette.background.paper, color: 'white',height: '10%', display: 'flex', }}>
                    <p style={{margin: 'auto'}} >KUmatch</p>
                </Box>
                <Divider />
                <Box sx={{height: '90%', display: 'flex',flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography sx={{fontFamily:'Montserrat'}} variant="p" component="span">You have seen everyone</Typography>
                    <br/>
                    <Typography sx={{fontFamily:'Montserrat', fontSize: '0.8em'}} variant="p" component="span">Please be patient</Typography>
                    <br/>
                    <Typography sx={{fontFamily:'Montserrat'}} variant="p" component="span">Time since release: {timePassed}</Typography>
                </Box>
            </Box>
        )
    }
}