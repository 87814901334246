import Box from '@mui/material/Box';
import { Avatar, Button, Typography, Backdrop, CircularProgress, Paper, IconButton, InputBase,  Divider, FormControl, FormControlLabel, Select, MenuItem, RadioGroup, Radio, Alert } from '@mui/material';
import moment from "moment";
import { useState, useEffect, useRef } from 'react';
import { countryList } from '../countriesList';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import apiClient from "../http-common";
import { useDispatch, useSelector } from 'react-redux';
import { setUser} from '../features/app/appSlice'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Montserrat',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));


  function ImgContent({img}){
    if(typeof img === 'string'){
        return (
            (<img style={{objectFit: 'contain', borderRadius: '5px 5px 5px 5px'}}
                                alt="not found"
                                width={"100%"}
                                height={"100%"}
                                src={img}
            />)
        )
    }else if(img){
        return (
            (<img style={{objectFit: 'contain', borderRadius: '5px 5px 5px 5px'}}
                                alt="not found"
                                width={"100%"}
                                height={"100%"}
                                src={URL.createObjectURL(img)}
            />)
        )
    }else{
        return <AddCircleOutlineIcon sx={{ fontSize: 80 }}/>
    }
  }

  function PhotoUpdater({user, setContent}){
    const [selectedImage1, setSelectedImage1] = useState(user.photos[0]);
    const [selectedImage2, setSelectedImage2] = useState(user.photos[1]);
    const [selectedImage3, setSelectedImage3] = useState(user.photos[2]);

    const hiddenFileInput1 = useRef(null);
    const hiddenFileInput2 = useRef(null);
    const hiddenFileInput3 = useRef(null);

    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [isLoading, setIsloading] = useState(false);


    const dispatch = useDispatch();

    async function handleUpdatePhotos(){
        const files = [selectedImage1, selectedImage2, selectedImage3];

        // Check if all files are less than 15MB
        const maxSize = 15 * 1024 * 1024; // 15MB in bytes
        const oversizedFiles = files.filter(file => {
            return file instanceof File ? file.size > maxSize : false
        });
        if (oversizedFiles.length > 0) {
            console.log(`The following files are too large. Maximum size is 15MB. ${oversizedFiles.map(file => file.name).join(', ')}`);
            setError(true)
            setErrorText('Maximum file size is 15MB')
            return;
        }
    
        // Check if all files are images
        const isImage = files.every(file => {
            return file instanceof File ? file.type.startsWith('image/') : true
        });
        if (!isImage) {
            console.log('Selected files are not all images.');
            setError(true)
            setErrorText('Please only upload images')
            return;
        }

        setError(false)
    
        const formData = new FormData();
    
        for (let i = 0; i < files.length; i++) {
            if (files[i] instanceof File) {
                formData.append('files', files[i]);
            } else {
                formData.append('urls', files[i]);
            }
        }
    
        try {
            setIsloading(true)
            apiClient.post(`users/${user._id}/photos`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                dispatch(setUser(response.data));
                setIsloading(false)
                setContent('profile');
                //console.log(response.data);
            })
            .catch(error => {
                setIsloading(false)
                if(error.response.data.message === 'No file uploaded.'){
                    setError(true)
                    setErrorText('Please upload a new file')
                }
                //console.log(error.response.data.message);
            });
        } catch (err) {
            console.log(err.response?.data);
        }
    }




    return(
        <Box sx={{height: '100%', width: '100%'}}>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{color: 'white', width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
                <Box sx={{height: '10%', width: '100%', display: 'flex', alignItems: 'center'}}>
                    <IconButton onClick={() => setContent('profile')} sx={{width: '15%', height: '100%'}}>
                        <ArrowBackIcon/>
                    </IconButton>
                    {error ? <Alert sx={{m: 'auto', width: '70%'}} severity="error">{errorText}</Alert> : <h3>Update your photos</h3>}
                </Box>
                <Box sx={{height: '80%', width: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column'}}>
                    <Paper sx={{background: 'white', overflow: 'hide', width: '90%', minHeight: '90%', m: 'auto', mt: '5%', display: 'flex'}}>
                        <Button sx={{m: 'auto', width: '95%', height: '95%'}} onClick={() => hiddenFileInput1.current.click()}>
                            <ImgContent img={selectedImage1}/>
                        </Button>
                    </Paper>
                    <input type="file" name="myImage1" ref={hiddenFileInput1} style={{display: 'none'}}
                        onChange={(event) => {
                            setSelectedImage1(event.target.files[0]);
                    }}/>
                    <Paper sx={{background: 'white', width: '90%', minHeight: '90%', m: 'auto', mt: '5%', display: 'flex'}}>
                        <Button sx={{m: 'auto', overflow: 'hidden', width: '95%', height: '95%'}} onClick={() => hiddenFileInput2.current.click()}>
                        <ImgContent img={selectedImage2}/>

                        </Button>
                    </Paper>
                    <input type="file" name="myImage2" ref={hiddenFileInput2} style={{display: 'none'}}
                        onChange={(event) => {
                            setSelectedImage2(event.target.files[0]);
                    }}/>
                    <Paper sx={{background: 'white', width: '90%', minHeight: '90%', m: 'auto', my: '5%', display: 'flex'}}>
                        <Button sx={{m: 'auto', overflow: 'hidden', width: '95%', height: '95%'}} onClick={() => hiddenFileInput3.current.click()}>
                            <ImgContent img={selectedImage3}/>

                        </Button>
                    </Paper>
                    <input type="file" name="myImage3" ref={hiddenFileInput3} style={{display: 'none'}}
                        onChange={(event) => {
                            setSelectedImage3(event.target.files[0]);
                    }}/>
                </Box>
                <Box sx={{height: '10%', display: 'flex'}}>
                    <Button variant="outlined" sx={{height: '80%', m: 'auto'}} onClick={handleUpdatePhotos}>
                        <Typography sx={{fontSize: '1.8em', fontFamily:'Montserrat', p: '2%'}} variant="p" component="span">Update</Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}  

export default function Profile(){
    const [userInfo, setUserInfo] = useState({
        major: '',
        nationality: '',
        gender: '',
        preference: '',
        enabled: null
    });
    const dispatch = useDispatch()
    const user = useSelector(state => state.app.user)
    const isMobile = useSelector(state => state.app.isMobile)
    const [content, setContent] = useState("profile")


    useEffect(() => {
        setUserInfo({
            major: user.major,
            nationality: user.nationality,
            gender: user.gender,
            preference: user.preference,
            enabled: user.enabled
        })
    },[]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({
          ...userInfo,
          [name]: value,
        });

      };
    
      function getAge(dob){
        var today = new Date();
        var birthDate = new Date(dob.substring(0, 4), dob.substring(4, 6) - 1, dob.substring(6, 8));
        var age = today.getFullYear() - birthDate.getFullYear();
        var month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;    
    }

      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await apiClient.put(`/users/${user._id}`, userInfo);
            dispatch(setUser(response.data))
            return response.data;
            } catch (error) {
            console.error(error);
            }
        
      };

    return(
        <Box sx={{height: '90%', display: 'flex', flexDirection: 'column'}}>
            {content === 'profile' ? 
            (<Box sx={{position: 'relative', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Box sx={{color: 'white', height: '40%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <IconButton onClick={() => setContent('photo')} sx={{width: isMobile ? '55%' : '43%', height: '80%'}}>
                        <Avatar sx={{height: '100%', width: '100%', mt: '3%'}} alt="Remy Sharp" src={user.photos[0]}/>
                    </IconButton>
                    <Typography sx={{fontFamily:'Montserrat'}} variant="p" component="span">{user.firstName}, {getAge(user.dob)}</Typography>
                </Box>
                <Divider style={{width:'80%'}} />
                <Box sx={{overflow: 'auto', color: 'white', height: '60%', width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <FormControl sx={{pt: '2%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <Typography sx={{fontFamily:'Montserrat', p: '2%'}} variant="p" component="span">Major</Typography>
                        <Select
                            sx={{ textAlign: 'left' }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: '40vh',
                                        maxWidth: '100%'
                                    }
                                }
                            }}
                            fullWidth
                            name="major"
                            labelId="major-select"
                            id="major-select"
                            value={userInfo.major}
                            label="Major"
                            onChange={handleChange}
                            input={<BootstrapInput />}
                            >
                            <MenuItem value={'Computer Engineering'}>Computer Engineering</MenuItem>
                            <MenuItem value={'Mechanical Engineering'}>Mechanical Engineering</MenuItem>
                            <MenuItem value={'Chemical Engineering'}>Chemical Engineering</MenuItem>
                            <MenuItem value={'Electrical Engineering'}>Electrical Engineering</MenuItem>
                            <MenuItem value={'Business Management'}>Business Management</MenuItem>
                            <MenuItem value={'Economics'}>Chemical Engineering</MenuItem>
                            <MenuItem value={'Law'}>Law</MenuItem>
                            <MenuItem value={'Medicine'}>Medicine</MenuItem>
                            <MenuItem value={'MAVA'}>MAVA</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{pt: '2%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <Typography sx={{fontFamily:'Montserrat', p: '2%'}} variant="p" component="span">Nationality</Typography>
                        <Select
                            sx={{ textAlign: 'left' }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: '40vh',
                                        maxWidth: '100%'
                                    }
                                }
                            }}
                            fullWidth
                            name="nationality"
                            labelId="nationality-select"
                            id="nationality-select"
                            value={userInfo.nationality} // <-- Update this line
                            label="Nationality"
                            onChange={handleChange}
                            input={<BootstrapInput />}
                            >
                            {countryList.map(countryObj => {
                                return  <MenuItem key={countryObj.code} value={countryObj.code}>
                                            {countryObj.name}
                                        </MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{pt: '2%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <Typography sx={{fontFamily:'Montserrat', p: '2%'}} variant="p" component="span">Gender</Typography>
                        <RadioGroup
                            aria-labelledby="gender-radio-buttons"
                            name="gender"
                            value={userInfo.gender}
                            onChange={handleChange}
                        >
                            <Box sx={{pl: '10%', display: 'flex', flexDirection: 'column'}}>
                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                            </Box>   
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{pt: '2%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <Typography sx={{fontFamily:'Montserrat', p: '2%'}} variant="p" component="span">Preference</Typography>
                        <RadioGroup
                            row
                            aria-labelledby="preference-radio-buttons"
                            name="preference"
                            value={userInfo.preference}
                            onChange={handleChange}
                        >
                            <Box sx={{pl: '10%', display: 'flex', flexDirection: 'column'}}>
                                <FormControlLabel value="women" control={<Radio />} label="Women" />
                                <FormControlLabel value="men" control={<Radio />} label="Men" />
                            </Box>
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{pt: '2%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <Typography sx={{fontFamily:'Montserrat', p: '2%'}} variant="p" component="span">Visibility</Typography>
                        <RadioGroup
                            row
                            aria-labelledby="enabled-radio-buttons"
                            name="enabled"
                            value={userInfo.enabled}
                            onChange={handleChange}
                        >
                            <Box sx={{pl: '10%', display: 'flex', flexDirection: 'column'}}>
                                <FormControlLabel value="true" control={<Radio />} label="Enabled" />
                                <FormControlLabel value="false" control={<Radio />} label="Disabled" />
                            </Box>
                        </RadioGroup>
                    </FormControl>
                </Box>
                <IconButton onClick={handleSubmit} sx={{position: 'absolute', bottom: '2%', right: '2%'}}>
                    <CheckCircleIcon color="success" sx={{ fontSize: '3em' }}/>      
                </IconButton>
            </Box>) : <PhotoUpdater user={user} setContent={setContent}/> }
        </Box>
    )
}