import './App.css';
import * as React from 'react';
import MainView from './components/MainView'
import LoginView from './components/LoginView';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsMobile } from './features/app/appSlice';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { setUser, setLoading } from './features/app/appSlice';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { CssBaseline } from '@mui/material';
import socket from './socket';
import axios from './http-common';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    mode: 'dark',
    primary: {
      main: red[900],
    },
    secondary: {
      main: green[500],
    },
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
    success: {
      main: '#39BF42',
    },
    error: {
      main: '#D61313'
    }

  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:focus': {
            borderColor: 'rgba(0, 0, 0, 0.23)', // change the color to your preferred value
          },
        },
      },
    },
  },
});

function App() {
  const user = useSelector(state => state.app.user)
  const isMobile = useSelector(state => state.app.isMobile)
  const dispatch = useDispatch()
  const [width, setWidth] = useState(window.innerWidth);
  const [loading, setLocalLoading] = useState(false);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const token = localStorage.getItem('token');

    if (token) {
      setLocalLoading(true)
      // send JWT with each request
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      // verify JWT on server-side
      axios.get('/users/verify-token')
        .then(response => {
          dispatch(setLoading(true))
          dispatch(setUser(response.data))
          setLocalLoading(false)
        })
        .catch(error => {
          console.log(error)
          setLocalLoading(false)
          dispatch(setUser(null))
        });
    }

    socket.on("connect", () => {
      console.log("Connected to server");
    });

    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  
  //const isMobile = width <= 768;

  dispatch(setIsMobile(width <= 768))

  const contentEvaluator = () =>{
    if(loading){
              return <Box sx={{background: theme.palette.background.default, width: isMobile ? "100vw" : "35vw", height: "calc(var(--vh, 1vh) * 100)", borderRadius: '0px 0px 0px 0px'}}>
              <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                      open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                      </Box>
    }else if(user){
      return <MainView />
    }else{
      return <LoginView />
    }
    // switch(content){
    //     case "login" : return <LoginView  setContent={setContent}/>;
    //     case "main" : return <MainView />;
    //     default : return <h1>Hello World!</h1>;
    // }      
}
  return (
      <div className="App App-header">
        <ThemeProvider theme={theme}>
          {contentEvaluator()}
        </ThemeProvider>
      </div>
  );
}


export default App;
