import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { Dialog, List, ListItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { updateChatInChats, addChat, setOpenedChat, addMsgToChat, markMsgsAsSeen } from '../features/app/appSlice'
import apiClient from "../http-common";
import moment from 'moment';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import {useEffect, useRef, useState} from 'react';
import { useTheme } from '@mui/material';
import httpCommon from '../http-common';
import socket from '../socket';

function CardDialog({selectedUser, onClose}){
    const isMobile = useSelector(state => state.app.isMobile)
    const dispatch = useDispatch()
    const theme = useTheme()


    function handleClose(){
        onClose()
    }

    if(selectedUser !== null){
    return ( 
        <Dialog onClose={handleClose} open={true}>
            <Paper sx={{width: isMobile ? '80vw' : '30vw', 
                        height: '85vh', 
                        background: theme.palette.background.default,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                <Paper sx={{width: '95%', height: '95%', background: theme.palette.background.paper}}>
                    <Box sx={{width: "100%", height: "100%"}}>
                        <Box style={{height: '100%', position: 'relative'}}>
                            <Box sx={{height: '100%', pt: '4%'}}>
                                <List disablePadding sx={{ width: '100%', position: 'relative', overflow: 'auto', maxHeight: '96%'}}>
                                    {selectedUser.photos.map(photoUrl => {
                                        return  <ListItem sx={{pt: '0px'}}>
                                                    <img style={{display: 'block', width: '100%', height: '100%', objectFit: 'cover'}} src={photoUrl} alt="User Avatar"></img>
                                                </ListItem>
                                    })}
                                </List> 
                            </Box>
                            <Box style={{position: 'absolute', bottom: '0', left: '0', width: '100%', height: '20%', borderRadius: '0px 0px 3px 3px', backgroundImage: 'linear-gradient(to bottom, transparent, #901124)'}}>
                                <Box style={{textAlign: 'left', position: 'absolute', bottom: '5px', left: '5px', color: 'white', padding: '5px'}}>
                                    <span style={{fontWeight: 'bold'}}>{selectedUser.firstName}, {getAge(selectedUser.dob)}</span>
                                    <span> </span>
                                    <span className={`fi fi-${selectedUser.nationality.toLowerCase()}`}></span>
                                    <br />
                                    <span style={{fontSize: '0.7em'}}>{selectedUser.major}</span>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Paper>
        </Dialog>
    )
    }
    
}

function getAge(dob){
    var today = new Date();
    var birthDate = new Date(dob.substring(0, 4), dob.substring(4, 6) - 1, dob.substring(6, 8));
    var age = today.getFullYear() - birthDate.getFullYear();
    var month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;    
}


export default function ChatView(props){
    const openedChat = useSelector(state => state.app.openedChat)
    const chats = useSelector(state => state.app.chats)
    const user = useSelector(state => state.app.user)
    const isMobile = useSelector(state => state.app.isMobile)
    const messageInputRef = useRef(null);
    const dispatch = useDispatch()
    const chatBoxRef = useRef(null);
    const [open, setOpen] = useState(false);


    const theme = useTheme()

    const newMessageHandler = (chat) => { 
        const chatWithId = chats.find(c => c._id === chat._id);
        if(chatWithId){ 
            dispatch(updateChatInChats(chat))
            if(openedChat._id === chat._id){
                dispatch(setOpenedChat(chat));
                httpCommon.post(`/chats/${openedChat._id}/seen`, {
                    userId: user._id
                })
                .then(response => {
                    console.log(response.data);
                    dispatch(setOpenedChat(response.data)) 
                })
                .catch(error => {
                    console.log(error.response.data.message);
                });
            }
        }
    };
  
    const seenMessageHandler = (chat) => {
        const chatWithId = chats.find(c => c._id === chat._id);
        if(chatWithId){ 
            dispatch(updateChatInChats(chat))
            if(openedChat._id === chat._id){
                dispatch(setOpenedChat(chat));
            }
        }
    };

    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }

        httpCommon.post(`/chats/${openedChat._id}/seen`, {
            userId: user._id
          })
          .then(response => {
            console.log(response.data);
            dispatch(setOpenedChat(response.data)) 
          })
          .catch(error => {
            console.log(error.response.data.message);
          });

        socket.on("newMessage", newMessageHandler);
        socket.on("seenMessage", seenMessageHandler);
      
        return () => {
          socket.off("newMessage", newMessageHandler);
          socket.off("seenMessage", seenMessageHandler);
        };
      }, []);

    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }      
    }, [openedChat]);

    async function createNewChat(message, time){
        const newChat = {
            messages: [{body: message, time: time, sentBy: user._id, seen: false}],
            participants: [openedChat.participants[0]._id, openedChat.participants[1]._id]
        }
        try {
            const res = await apiClient.post("/chats", newChat, {
                headers: {
                    "x-access-token": "token-value",
                },
            });
            console.log(res.data)
            dispatch(setOpenedChat(res.data))
            dispatch(addChat(res.data))
        } catch (err) {
            console.log(err)
        }
    }

    async function sendMsg(messageBody, time){
        const message = {body: messageBody, time: time, sentBy: user._id, seen: false};
        const chatID = openedChat._id;
        
        dispatch(addMsgToChat(message))
        apiClient.post(`/chats/${chatID}/messages`, {message})
          .then(response => {
            dispatch(setOpenedChat(response.data))
            console.log(response.data);
          })
          .catch(error => {
            console.log(error.response.data);
          });        
    }

    async function handleSendMsg(event){
        event.preventDefault();
        const message = messageInputRef.current.value;
        const time = Date.now()

        if(message){
            if(openedChat.messages.length){
                sendMsg(message, time)
            }else{
                createNewChat(message, time)
            }
        }else{
            console.log('type smth')
        }

        messageInputRef.current.value = ''
    }

    function getMessageTime(date){
        const now = new Date(date);
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const messageDate = new Date(date);
        const messageTime = messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });


        return messageTime;
    }

    function MessageStatus({sx, message}){
        if(message.sentBy === user._id){
            return message.seen ? <DoneAllIcon sx={{...sx, color: '#53bdeb'}}/> : <DoneAllIcon sx={{...sx, color: '#a6b9c6'}}/>
        }
    }

    function sentByAuthUser(message){
        return message.sentBy === user._id
    }

    function getSelectedUser(){
        console.log((openedChat.participants[0]._id === user._id ? openedChat.participants[1] : openedChat.participants[0]))
        return (openedChat.participants[0]._id === user._id ? openedChat.participants[1] : openedChat.participants[0])
    }


    

    return(
        <Box sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            {open ? <CardDialog selectedUser={getSelectedUser()} onClose={() => setOpen(false)}/> : null}
            <Box sx={{background: theme.palette.background.paper,height: '10%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}} >
                <IconButton sx={{width:'15%', height: '100%'}} onClick={() => props.setContent('chats')}>
                    <ArrowBackIcon sx={{width: '80%', height: '80%'}}/>
                </IconButton>
                <Button onClick={() => setOpen(true)} sx={{width: '85%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <Avatar sx={{width: isMobile ? '17%' : '12%', height: '90%'}} alt="Remy Sharp" src={user._id === openedChat.participants[0]._id ? openedChat.participants[1].photos[0] : openedChat.participants[0].photos[0]}/>
                    <Box style={{textAlign: 'left',  color: 'white', padding: '5%', display: 'flex', flexDirection: 'column'}}>
                        <Typography sx={{fontFamily:'Montserrat'}} variant="h6" component="span">{user._id === openedChat.participants[0]._id ? openedChat.participants[1].firstName : openedChat.participants[0].firstName}</Typography>
                    </Box> 
                </Button>
            </Box>
            <Box ref={chatBoxRef} sx={{height: '80%', display: 'flex', flexDirection: 'column', overflowY: 'scroll'}}>       
                <div style={{flex: '1 1 auto'}}></div>
                {openedChat.messages.map( (message, index) =>
                    <Box key={message.time} sx={{color: 'white', display: 'flex', flexDirection: 'column'}}>
                        <MessagesDate message={message} prevMsg={openedChat.messages[index-1]}/>
                        <Paper  sx={{
                            background: sentByAuthUser(message) ? '#766AC7': '#919191',
                            pr: '15%', pl: '5%', pb: '3%', pt: '2%',  textAlign: sentByAuthUser(message) ? 'right' : 'left',
                            mx: '3%', mb: '3%', mt: index === 0 ? '3%' : 0, maxWidth: '50%',
                            alignSelf: sentByAuthUser(message) ? 'flex-end' : 'flex-start', borderRadius: '20px 20px 20px 20px',
                            display: 'flex', alignItems: 'center', position: 'relative',
                            wordWrap: 'break-word' // added property to allow text to wrap to next line
                        }}>
                            <Typography sx={{fontSize: '0.7em', maxWidth: '100%', fontFamily:'Montserrat'}} variant="h6" component="span">{message.body}</Typography>
                            <Box sx={{position: 'absolute', right: '12px', bottom: '0', display: 'flex'}}>
                                <Typography sx={{fontSize: '0.5em', color: 'white', fontFamily:'Montserrat'}} variant="h6" component="span">{getMessageTime(message.time)}</Typography>
                                <MessageStatus sx={{fontSize: '0.6em', fontFamily:'Montserrat', alignSelf: 'flex-end'}} message={message}/>
                            </Box>
                        </Paper>
                    </Box>
                )}        
            </Box>
            <Box sx={{height: '10%', width: '100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                <form onSubmit={handleSendMsg} style={{width: '90%'}}>
                    <Paper sx={{background:'#2C3338', display: 'flex', alignItems: 'center', width: '100%', borderRadius: '20px 20px 20px 20px' }}>
                        <InputBase
                            sx={{ fontFamily:'Montserrat', color:'white', ml: 1, flex: 1 }}
                            placeholder="Message"
                            inputRef={messageInputRef}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton type="submit" sx={{ p: '10px' }} aria-label="directions">
                            <SendIcon />
                        </IconButton>
                    </Paper>
                </form>
            </Box>
        </Box>
    )
}

function MessagesDate({ message, prevMsg }) {
    if(!prevMsg){
        const msgDate = new Date(message.time);
        const formattedDate = msgDate.toLocaleString("en-US", {
            month: "long",
            day: "numeric",
          });
    
          return (
            <Typography
              sx={{ p: '2%', fontSize: "0.7em", maxWidth: "100%", fontFamily: "Montserrat" }}
              variant="h6"
              component="span"
            >
              {formattedDate}
            </Typography>
          );
    }
    // Convert message time to a Date object
    const msgDate = new Date(message.time);
  
    // Convert lastMsgDate to a Date object
    const prevMsgDate = new Date(prevMsg.time);
  
    // Check if the day and month of the message time
    // are different from the last stored date
    const isNewDate = (
      msgDate.getDate() !== prevMsgDate.getDate() ||
      msgDate.getMonth() !== prevMsgDate.getMonth()
    );
  
    if (isNewDate) {
      // Update lastMsgDate with the new date
      const formattedDate = msgDate.toLocaleString("en-US", {
        month: "long",
        day: "numeric",
      });

      return (
        <Typography
          sx={{ p: '2%',  fontSize: "0.7em", maxWidth: "100%", fontFamily: "Montserrat" }}
          variant="h6"
          component="span"
        >
          {formattedDate}
        </Typography>
      );
    }
  }