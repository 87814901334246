import { Box, Divider, Paper, Button, Dialog, List, ListItem, FormControl, Fab, Typography, InputLabel, Select, InputBase, MenuItem } from "@mui/material"
import { useTheme } from '@mui/material/styles';
import { countryList } from "../countriesList";
import { useState } from "react";
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import ChatIcon from '@mui/icons-material/Chat';
import httpCommon from "../http-common";
import ChatView from "./ChatView";
import { useDispatch, useSelector } from "react-redux";
import { setSearches,setOpenedChat } from '../features/app/appSlice'
import CircularProgress from '@mui/material/CircularProgress';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Montserrat',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));


  function CardDialog({selectedUser, onClose}){
    const isMobile = useSelector(state => state.app.isMobile)
    const theme = useTheme();

    function handleClose(){
        onClose()
    }

    if(selectedUser !== null){
    return ( 
        <Dialog onClose={handleClose} open={true}>
            <Paper sx={{width: isMobile ? '80vw' : '30vw', 
                        height: '85vh', 
                        background: theme.palette.background.default,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                <Paper sx={{width: '95%', height: '95%', background: theme.palette.background.paper}}>
                    <Box sx={{width: "100%", height: "100%"}}>
                        <Box style={{height: '100%', position: 'relative'}}>
                            <Box sx={{height: '100%', pt: '4%'}}>
                                <List disablePadding sx={{ width: '100%', position: 'relative', overflow: 'auto', maxHeight: '96%'}}>
                                    {selectedUser.photos.map(photoUrl => {
                                        return  <ListItem key={photoUrl} sx={{pt: '0px'}}>
                                                    <img style={{display: 'block', width: '100%', height: '100%', objectFit: 'cover'}} src={photoUrl} alt="User Avatar"></img>
                                                </ListItem>
                                    })}
                                </List> 
                            </Box>
                            <Box style={{position: 'absolute', bottom: '0', left: '0', width: '100%', height: '20%', borderRadius: '0px 0px 3px 3px', backgroundImage: 'linear-gradient(to bottom, transparent, #901124)'}}>
                                <Box style={{textAlign: 'left', position: 'absolute', bottom: '5px', left: '5px', color: 'white', padding: '5px'}}>
                                    <span style={{fontWeight: 'bold'}}>{selectedUser.firstName} {selectedUser.lastName}, {getAge(selectedUser.dob)}</span>
                                    <span> </span>
                                    <span className={`fi fi-${selectedUser.nationality.toLowerCase()}`}></span>
                                    <br />
                                    <span style={{fontSize: '0.7em'}}>{selectedUser.major}</span>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Paper>
        </Dialog>
    )
    }
    
}
 
function getAge(dob){
    var today = new Date();
    var birthDate = new Date(dob.substring(0, 4), dob.substring(4, 6) - 1, dob.substring(6, 8));
    var age = today.getFullYear() - birthDate.getFullYear();
    var month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;    
}

export default function Search(){
    const theme = useTheme();
    const [searchInfo, setSearchInfo] = useState({
        major: 'any',
        nationality: 'any',
        preference: '',
    });
    const searches = useSelector(state => state.app.searches)
    const chats = useSelector(state => state.app.chats)
    const user = useSelector(state => state.app.user)
    const dispatch = useDispatch()
    const [selectedUser, setSelectedUser] = useState(null)
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('search');
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchInfo({
          ...searchInfo,
          [name]: value,
        });

    };

    const handleSearchClick = () => {
        const query = {
            nationality: searchInfo.nationality,
            major: searchInfo.major,
          };

        setLoading(true)
        httpCommon.get('/users/', { params: query })
        .then(response => {
            console.log(response.data); // log the retrieved users
            dispatch(setSearches(response.data))
            setLoading(false)
        })
        .catch(error => {
            console.error(error);
            setLoading(false)
        });
    }

    function NationalitySelect({}){
        return  <FormControl sx={{width: '40%'}}>
                    <InputLabel error={false} color="common" id="nationality-select-label">Nationality</InputLabel>
                    <Select
                        error={false}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: '40vh',
                                    maxWidth: '30%'
                                }
                            }
                        }}
                        sx={{
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(228, 219, 233, 0.25)',
                            },
                            }}
                        name="nationality"
                        labelId="nationality-select"
                        id="nationality-select"
                        value={searchInfo.nationality}
                        label="Nationality"
                        onChange={handleChange}
                        >
                        <MenuItem key="any" value="any">
                            Any
                        </MenuItem>
                        {countryList.map(countryObj => {
                            return  <MenuItem key={countryObj.code} value={countryObj.code}>
                                        {countryObj.name}
                                    </MenuItem>
                        })}
                    </Select>
                </FormControl>
    }

    function MajorSelect({}){
        return  <FormControl sx={{width: '40%'}}>
                    <InputLabel error={false} color="common" id="major-select-label">Major</InputLabel>
                    <Select error={false}
                        sx={{
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(228, 219, 233, 0.25)',
                        },
                        }}
                        name="major"
                        labelId="major-select"
                        id="major-select"
                        value={searchInfo.major}
                        label="Major"
                        onChange={handleChange}
                        >
                        <MenuItem key="any" value={'any'}>Any</MenuItem>
                        <MenuItem key="comp" value={'Computer Engineering'}>Computer Engineering</MenuItem>
                        <MenuItem key="mech" value={'Mechanical Engineering'}>Mechanical Engineering</MenuItem>
                        <MenuItem key="chem" value={'Chemical Engineering'}>Chemical Engineering</MenuItem>
                        <MenuItem key="elec" value={'Electrical Engineering'}>Electrical Engineering</MenuItem>
                        <MenuItem key="bm" value={'Business Management'}>Business Management</MenuItem>
                        <MenuItem key="econ" value={'Economics'}>Chemical Engineering</MenuItem>
                        <MenuItem key="law" value={'Law'}>Law</MenuItem>
                        <MenuItem key="med" value={'Medicine'}>Medicine</MenuItem>
                        <MenuItem key="mava" value={'MAVA'}>MAVA</MenuItem>
                    </Select>
                </FormControl>
    }

    function handleResultPhotoClick(user){
        setSelectedUser(user)
        setOpen(true)
    }

    function handleMessageClick(search){
        let chat = {}
        const participants = [search._id, user._id]

        console.log(chats)
        for (var i = 0; i < chats.length; i++) { 
            if(chats[i].participants.every(participant => participants.includes(participant._id))){
                dispatch(setOpenedChat(chats[i]))
                setContent('chatView')
                return
            }
        }

        httpCommon.get('/chats/participants', {
            params: {
                participants: [search._id, user._id]
            }
        })
        .then(response => {
            console.log(response.data);
            dispatch(setOpenedChat(response.data))
            setContent('chatView')
        })
        .catch(error => {
            if(error.response.status === 404){
                const openedChat = {
                    messages: [],
                    participants: [ user, search]
                }
    
                dispatch(setOpenedChat(openedChat))
                setContent('chatView')
            }   
        }); 
    }

    function handleClose(){
        setOpen(false)
        setSelectedUser(null)
    }

    function SearchResults(){
        return <Box sx={{height: '79.7%', overflow: 'auto'}}>
                {searches.map((search, index) => {
                    return <Paper key={search._id} sx={{mx: 'auto', mt: index === 0 ? '5%' : 0, mb: '5%', width: '90%', height: '20%', display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                                <Button onClick={() => handleResultPhotoClick(search)} sx={{width: '25%', height: '90%', }}>
                                    <img style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '5px 5px 5px 5px'}} src={search.photos[0]}></img>
                                </Button>
                                <Box sx={{height: '80%', width: '50%', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
                                    <Typography sx={{fontSize: '0.8em', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                                        {search.firstName} {search.lastName}
                                    </Typography>                                        
                                    <Typography sx={{fontSize: '0.65em'}}>{getAge(search.dob)} years, <span className={`fi fi-${search.nationality.toLowerCase()}`}></span></Typography>
                                    <Typography sx={{fontSize: '0.65em'}}>{search.major}</Typography>
                                </Box>
                                <Fab onClick={() => handleMessageClick(search)} color="primary" aria-label="add">
                                    <ChatIcon />
                                </Fab>
                            </Paper>
                })}
                </Box>
    }

    function ProgressBox(){
        return <Box sx={{display:'flex', height: '79.7%'}}>
                    <CircularProgress sx={{m: 'auto'}}/>
                </Box>
    }

    if(content === 'chatView'){
        return  (<Box sx={{height: '90%'}}>
                    <ChatView setContent={setContent}/>
                </Box>)
    }else{
        return (
            <Box sx={{height: '90%', display: 'flex', flexDirection: 'column'}}>
                {open ? <CardDialog selectedUser={selectedUser} onClose={handleClose}/> : null}
                <Box  sx={{background: theme.palette.background.paper, color: 'white',height: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <p>Search</p>
                </Box>
                <Divider/>
                <Box sx={{height: '90%', width: '100%'}}>
                    <Box sx={{height: '20%', position: 'relative', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                        <NationalitySelect />
                        <MajorSelect />
                        <Fab onClick={handleSearchClick} color="primary" aria-label="add">
                            <SearchIcon />
                        </Fab>
                    </Box>
                    <Divider/>
                    {loading ? <ProgressBox/> : <SearchResults/>}
                </Box>
            </Box>
        )
    }
}